* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container-home {
  display: flex;
  font-family: "Red Hat Text", sans-serif;
  height: 100vh;
}

.leftColumn,
.rightColumn {
  flex: 1;
  padding: 20px;
  height: 100vh;
}

.leftColumn {
  max-width: 50%;
  padding: 80px 114px;
  text-align: center;
  background: #1c38a9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rightColumn {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 24px;
}

.space {
  flex: 1 0 0;
}

.image {
  height: 410px;
  margin-bottom: 32px;
}

.text {
  color: #fff;
  font-size: 56px;
  letter-spacing: 0;
  line-height: normal;
  font-style: normal;
  font-weight: 500;
}

.sub-text {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.formContainer {
  width: 50%;
  margin: 0 auto;
}

.submitButton {
  width: 100%;
  border: none;
  text-align: center;
  padding: 16px 32px;
  border-radius: 8px;
}

button {
  cursor: pointer;
  background-color: #1c38a9;
  color: white;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

button:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #c2c2c2;
}

.input-group {
  position: relative;
  margin-bottom: 25px;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 2px solid #818181;
  background: #fff;
  font-size: 16px;
  color: #606060;
  font-family: "Red Hat Text";
  line-height: 24px;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
select::placeholder {
  color: #606060;
  opacity: 1;
}

input[type="text"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
select::-ms-input-placeholder {
  color: #606060;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #606060;
}

input[type="text"]+label,
input[type="email"]+label,
select+label {
  color: #3c3c3c;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  pointer-events: none;
}

input[type="text"]:focus+label,
input[type="email"]:focus+label,
select:focus+label,
input[type="text"]:not(:placeholder-shown)+label,
input[type="email"]:not(:placeholder-shown)+label {
  top: 0;
  left: 10px;
  font-size: 12px;
  transform: translateY(-100%);
}

input[type="text"]+label,
input[type="email"]+label,
select+label {
  color: #3c3c3c;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;
  pointer-events: none;
}

select:not([value=""])+label {
  top: 0;
  left: 10px;
  font-size: 12px;
  transform: translateY(-100%);
}

.form-sub-text,
.form-logo {
  display: none;
}

.error-inline-text {
  color: red;
  font-size: 0.8rem;
}

.input-group.error input {
  border: 1px solid red;
}

@media (max-width: 767px) {
  .form-sub-text {
    display: block;
    color: #000;
    text-align: center;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 48px;
  }

  .form-logo {
    display: block;
    height: 24px;
    margin-bottom: 40px;
  }

  .leftColumn {
    display: none;
  }

  .rightColumn {
    max-width: 100%;
    padding: 0 23px;
  }

  .formContainer {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1550px) {
  .sub-text {
    font-size: 18px;
  }

  .text {
    font-size: 32px;
  }

  .image {
    width: 350px;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}