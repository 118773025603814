* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container-coverage {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Red Hat Text", sans-serif;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}

.header hr {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-bottom: 56px;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  width: 100%;
}

.benefitsColumn,
.coverageColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
}

.benefitsColumn {
  flex: 0.3;
}

.benefitsColumn ul {
  list-style-type: none;
  padding-left: 0;
}

.benefitsColumn ul li {
  color: #000;
  font-family: "Red Hat Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
}

.benefitsColumn ul li ul {
  list-style-type: disc;
  color: #000;
  padding-left: 1em;
}

.benefitsColumn ul li ul li {
  color: #3c3c3c;
  font-family: "Red Hat Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.coverageColumn {
  flex: 1;
}

table {
  box-shadow: 4px 8px 16px 0px rgba(34, 38, 42, 0.04),
    2px 4px 8px 0px rgba(34, 38, 42, 0.03);
  border-radius: 8px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-spacing: 0 1px;
}

th,
td {
  padding: 12px 16px;
}

th {
  background-color: #f3f5f7;
  overflow: hidden;
  color: #5c7389;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

tbody td {
  border-bottom: 1px solid #e0e0e0;
  color: #1a2732;
  font-family: "Red Hat Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: white;
}

table th:first-child {
  border-top-left-radius: 8px;
}

table th:last-child {
  border-top-right-radius: 8px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

table tr:last-child td {
  border-bottom: none;
}

.tableHeader {
  background: #f3f5f7;
}

.tableCell {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.footer {
  width: 100%;
}

.logo {
  height: 24px;
}

.title {
  color: #1c38a9;
  font-family: "Red Hat Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.column-title {
  color: #1c38a9;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.subtitle {
  color: #3c3c3c;
  font-family: "Red Hat Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginator button {
  margin: 0 8px;
  display: flex;
  width: 32px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3c3c3c;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  /* Por defecto sin color de fondo */
}

/* Estilo de botón de paginador cuando está activo */
.paginator button.active {
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  background-color: #1c38a9;
}

.loading-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.loading-text {
  color: #1c38a9;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.loading-image {
  margin-bottom: 8px;
  height: 48px;
}

.error-image {
  height: 380px;
  margin-bottom: 24px;
}

.error-text {
  color: #1c38a9;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 8px;
}

.error-sub-text {
  color: #606060;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
  max-width: 482px;
}

.error-button {
  display: flex;
  width: 482px;
  height: 56px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #1c38a9;
  color: #fff;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
}

.error-button:hover {
  background: #2645c0;
}

.table-container {
  height: 450px;
}

.card-container {
  display: none;
}

.coverage-form-logo {
  display: none;
}

.downloadButton {
  height: 48px;
  width: 232px;
  border: none;
  text-align: center;
  padding: 8px 8px;
  border-radius: 8px;
}

.flatButton {
  width: 100%;
  border: none;
  text-align: center;
  padding: 16px 32px;
  border-radius: 8px;

  cursor: pointer;
  color: #1c38a9;
  background-color: white;
  text-align: center;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 767px) {
  .downloadButton {
    height: 72px;
  }

  .coverage-form-logo {
    display: block;
    height: 24px;
    margin: auto;
    margin-bottom: 40px;
  }

  .title {
    color: #000;
    font-family: "Red Hat Text";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
  }

  .subtitle {
    color: #000;
    font-family: "Red Hat Text";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .header {
    margin-bottom: 0;
  }

  .header hr {
    display: none;
  }

  .content {
    flex-direction: column;
  }

  .coverageColumn,
  .benefitsColumn {
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    padding: 24px 16px;
  }

  .coverageColumn {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .table-container {
    display: none;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card-coverage {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    border-bottom: 1px solid #eaecf1;
    background: #f6f7fe;
  }

  .card-coverage span {
    color: #1a2732;
    font-family: "Red Hat Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .footer,
  .paginator {
    display: none;
  }

  .loading-container {
    padding: 16px 24px;
  }

  .error-button {
    max-width: 100%;
  }
}